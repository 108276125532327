import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { GoogleButton } from 'src/components/AuthForms/Components/Buttons/GoogleButton';
import ReactGA4 from 'react-ga4';
import { Sections } from 'src/legacy/Components/auth/_constants';
import { postUser } from 'src/legacy/Components/auth/_api';
import {
  ErrorMessage,
  FloatingLabel,
  FloatingLabelContainer,
  FloatingLabelInput,
  FloatingLabelInputNumberFormat,
} from 'src/components/Inputs/FloatingLabel';
import { PasswordInput } from '../Inputs/Password';
import { Button, Box, Heading, Text, FormControl } from '@chakra-ui/react';
import { EllipsisLoading } from 'src/components/loadings';
import { yup, yupResolver } from 'src/utils/form';
import { useForm } from 'react-hook-form';
import { getCookie } from 'src/utils/cookie';

type SignupFormProps = {
  navigate: any;
};

const YupSchema = yup.object({
  email: yup.string().required('Preencha o email').email('Email inválido'),
  name: yup.string().required('Preencha o nome'),
  password: yup
    .string()
    .required('Preencha a senha')
    .min(6, 'Sua senha precisa ter pelo menos 6 caracteres'),
  tel: yup
    .string()
    .required('Digite seu número de celular')
    // @ts-ignore
    .cellphone('Digite um número válido'),
});
type FormSchema = {
  email: string;
  name: string;
  password: string;
  tel: string;
};
const SignupForm: React.FC<SignupFormProps> = ({ navigate }) => {
  const [section, setSection] = useState(Sections.REGISTER);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormSchema>({
    resolver: yupResolver(YupSchema),
  });

  const { onChange: onEmailChange, ...registerEmail } = register('email');

  const [error, setError] = useState<string | null>(null);
  useEffect(() => {
    setError(null);
  }, [errors.email?.message]);

  const onSubmit = handleSubmit(async data => {
    try {
      await postUser({
        name: data.name,
        email: data.email,
        password: data.password,
        cellPhone: data.tel,
        utm: getCookie('uticketUtm'),
      });

      ReactGA4.event('sign_up', { method: data.email });

      setSection(Sections.EMAIL_SENT);
    } catch (er: any) {
      setError(er.data.message);
    }
  });

  return (
    <Box className="layout-steps">
      <Box
        className={classNames('auth-section', 'layout-step', {
          current: section === Sections.REGISTER,
        })}>
        <Heading mt="0">Cadastro</Heading>
        <GoogleButton>Cadastro com o Google</GoogleButton>
        <span className="or">ou</span>
        <form noValidate onSubmit={onSubmit}>
          <FormControl>
            <FloatingLabelContainer>
              <FloatingLabelInput
                type="name"
                {...register('name')}
                maxLength={60}
              />
              <FloatingLabel>Nome</FloatingLabel>
            </FloatingLabelContainer>
            <ErrorMessage mb="2">{errors.name?.message}</ErrorMessage>
          </FormControl>

          <FormControl>
            <FloatingLabelContainer>
              <FloatingLabelInput
                type="email"
                {...registerEmail}
                onChange={e => {
                  onEmailChange(e);
                  setError(null);
                }}
              />
              <FloatingLabel>Email</FloatingLabel>
            </FloatingLabelContainer>
            <ErrorMessage mb="2">{errors.email?.message ?? error}</ErrorMessage>
          </FormControl>

          <FormControl>
            <FloatingLabelContainer>
              <FloatingLabelInputNumberFormat
                {...register('tel')}
                type="tel"
                format="(##)#####-####"
              />
              <FloatingLabel>Celular</FloatingLabel>
            </FloatingLabelContainer>
            <ErrorMessage mb="2">{errors.tel?.message}</ErrorMessage>
          </FormControl>

          <FormControl>
            <PasswordInput {...register('password')} />
            <ErrorMessage>{errors.password?.message}</ErrorMessage>
          </FormControl>

          <Button
            type="submit"
            colorScheme="brand"
            rounded="full"
            w="full"
            mt="4"
            isLoading={isSubmitting}
            spinner={<EllipsisLoading color="white" />}>
            Criar conta
          </Button>
        </form>

        <Button
          className="link-to-other-sections"
          onClick={() => navigate('login')}
          w="full"
          bg="transparent"
          textColor="black"
          color="black"
          _hover={{ bg: 'transparent' }}
          _active={{ bg: 'transparent' }}>
          Já tem conta?
          <Box as="span" color="brand.500" ml="2">
            Login
          </Box>
        </Button>
      </Box>
      <Box
        className={classNames('auth-section', 'layout-step', {
          current: section === Sections.EMAIL_SENT,
        })}>
        <Box>
          <Heading>Quase lá</Heading>
          <Text>
            Agora é só seguir as instruções que enviamos por e-mail, não esquece
            de olhar a caixa de spam!
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default SignupForm;
