import { Button } from '@chakra-ui/react';
import { Icon } from '@chakra-ui/icon';
import React, { ReactNode, useState } from 'react';
import { authApi } from 'src/api';
import { EllipsisLoading } from 'src/components/loadings';
import { BiLogoGoogle } from 'react-icons/bi';
import { Box, Text } from '@chakra-ui/layout';
import { useCartStore } from 'src/Pages/Event/stores';
import { useParams } from 'react-router';
import { Buffer } from 'buffer';

const getConsentOAuthUrl = (href: string) =>
  authApi
    .get('oauth-signin', {
      params: { p: 'google' },
      headers: { 'X-Referer': href },
    })
    .then(res => res.data);

const buildHrefWithCart = (href: string, tickets: any[]) => {
  const hasTickets = tickets?.some(item => item.quantity > 0);
  if (hasTickets) {
    const encodedTickets = Buffer.from(JSON.stringify(tickets)).toString(
      'base64',
    );
    return `${href}?${encodedTickets}`;
  }
  return href;
};

const isInstagramBrowser = () => navigator.userAgent.includes('Instagram');

type Props = { children: ReactNode };

const GoogleButton = ({ children }: Props) => {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { eventID } = useParams();
  const { getEventCart } = useCartStore();

  const currentHref = buildHrefWithCart(
    window.location.href,
    getEventCart(eventID!),
  );

  const signIn = async () => {
    if (isInstagramBrowser()) {
      setError(
        'Login com Google não funciona dentro do Instagram. Utilize o Chrome ou Safari.',
      );
      return;
    }
    try {
      setLoading(true);
      const consentUrl = await getConsentOAuthUrl(currentHref);
      window.location.replace(consentUrl);
    } catch (er) {
      setLoading(false);
    }
  };

  return (
    <Box>
      <Button
        colorScheme="brand"
        rounded="full"
        w="full"
        isLoading={isLoading}
        spinner={<EllipsisLoading color="white" />}
        onClick={signIn}>
        <Icon as={BiLogoGoogle} boxSize={6} mr="2" />

        {children}
      </Button>
      {error && (
        <Text mt={2} textAlign="center" fontSize={18}>
          {error}
        </Text>
      )}
    </Box>
  );
};

export { GoogleButton };
