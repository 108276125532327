import {
  Box,
  Flex,
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormHelperText,
  FormHelperTextProps,
  FormLabel,
  FormLabelProps,
  HStack,
  Input,
  InputProps,
  Radio,
  RadioGroup,
  RadioGroupProps,
  Skeleton,
  Switch,
  SwitchProps,
  Text,
  Textarea,
  TextareaProps,
} from '@chakra-ui/react';
import React, { Suspense, useMemo, useRef, useState } from 'react';
import {
  ControllerRenderProps,
  useController,
  UseControllerProps,
  useFormContext,
} from 'react-hook-form';

import NumberFormat, { NumberFormatProps } from 'react-number-format';

import { ErrorMessage } from '@hookform/error-message';

import { mergeRefs } from '../utils/form';

import { DateTimeInput, DateTimePicker, Select } from './forms';
import { DateTimePickerProps } from './forms/DateTimePicker';
import { DateTimeInputProps } from './forms/DateTimeInput';
import { TooltipHelperIcon } from 'src/Pages/EventManagement/components';
import { EllipsisLoading } from 'src/components/loadings';
import JoditEditor from 'jodit-react';
import { useUserStore } from 'src/hooks/stores';
import ReactQuill from 'react-quill';
import { useToast } from 'src/hooks';

type FormControlledContextProps = {
  field?: ControllerRenderProps;
  invalid?: boolean;
  name?: string;
  isDisabled?: boolean;
};

export type HookFormControlProps = {
  isRequired?: boolean;
  isDisabled?: boolean;
} & Pick<UseControllerProps, 'name' | 'defaultValue'> &
  Omit<FormControlProps, 'defaultValue'>;

export type HookFormRadioGroupOption = {
  value: string;
  label: string;
  tooltipLabel: string;
};

export type HookFormRadioGroupProps = {
  options: HookFormRadioGroupOption[];
  isDisabled?: boolean;
} & Omit<RadioGroupProps, 'children'>;

const FormControlledContext = React.createContext({});

export const useFormControlled = (): FormControlledContextProps =>
  React.useContext(FormControlledContext);

export const HookFormControl: React.FC<HookFormControlProps> = props => {
  const { name, defaultValue, isRequired, isDisabled, ...rest } = props;
  const { control } = useFormContext();
  const {
    field,
    fieldState: { invalid },
  } = useController({ control, name, defaultValue });

  return (
    <FormControlledContext.Provider
      value={{ field, invalid, name, isDisabled }}>
      <FormControl
        id={name}
        isInvalid={invalid}
        isRequired={isRequired}
        isDisabled={isDisabled}
        {...rest}
      />
    </FormControlledContext.Provider>
  );
};

export const HookFormLabel: React.FC<FormLabelProps> = props => {
  const { isDisabled } = useFormControlled();
  return (
    <FormLabel
      display="flex"
      flexDirection="row"
      alignItems="center"
      minH="24px"
      mb={1}
      opacity={isDisabled ? 0.4 : 1}
      {...props}
    />
  );
};

export const HookFormHelperText: React.FC<FormHelperTextProps> = props => {
  return (
    <FormHelperText
      mt={1}
      color="gray.500"
      lineHeight="normal"
      fontSize="sm"
      fontWeight="semibold"
      {...props}
    />
  );
};

export const HookFormErrorMessage = (props: any) => {
  const { name } = useFormControlled();
  return (
    <ErrorMessage
      as={FormErrorMessage}
      mt={1}
      color="red.500"
      lineHeight="normal"
      fontSize="sm"
      fontWeight="semibold"
      {...{ name }}
      {...props}
    />
  );
};

export const HookFormInput = React.forwardRef<
  HTMLFormElement,
  InputProps & { isUpperCase?: boolean }
>((props, ref) => {
  const { isUpperCase, onChange, maxLength, ...rest } = props;
  const { field, invalid } = useFormControlled();
  const [charCount, setCharCount] = React.useState(0);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = isUpperCase
      ? event.target.value.toUpperCase()
      : event.target.value;
    setCharCount(newValue.length);

    field?.onChange(newValue);
    onChange?.(event);
  };
  React.useEffect(() => {
    if (field?.value) {
      setCharCount(field.value.length);
    }
  }, [field?.value]);
  return (
    <Flex direction="column" gap="0" position="relative">
      <Input
        maxLength={maxLength}
        onChange={handleOnChange}
        onBlur={field?.onBlur}
        value={field?.value}
        name={field?.name}
        id={field?.name}
        ref={mergeRefs([field?.ref, ref])}
        isInvalid={invalid}
        {...rest}
        _focus={{
          borderColor: 'black',
        }}
      />
      {(field?.value?.length ?? 0) > 0 && maxLength! > 0 && (
        <Text
          right="0"
          position="absolute"
          color={'gray.400'}
          top="calc(100% + 2px)"
          lineHeight="1"
          mt="2px">
          {charCount}/{maxLength}
        </Text>
      )}
    </Flex>
  );
});

export const HookFormCepInput = React.forwardRef<
  HTMLInputElement,
  InputProps & NumberFormatProps & { isUpperCase?: boolean }
>((props, ref) => {
  const { isUpperCase, onChange, ...rest } = props;
  const { field, invalid } = useFormControlled();

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    field?.onChange(
      isUpperCase ? event.target.value.toUpperCase() : event.target.value,
    );
    onChange?.(event);
  };

  return (
    <NumberFormat
      inputMode="numeric"
      format="#####-###"
      customInput={Input}
      onValueChange={values => {
        const event = {
          target: {
            name: field?.name,
            value: isUpperCase ? values.value.toUpperCase() : values.value,
          },
        };
        handleOnChange(event as React.ChangeEvent<HTMLInputElement>);
      }}
      value={field?.value}
      name={field?.name}
      id={field?.name}
      getInputRef={mergeRefs([field?.ref, ref])}
      isInvalid={invalid}
      {...rest}
    />
  );
});

export const HookFormTextarea = React.forwardRef<
  HTMLFormElement,
  TextareaProps
>((props, ref) => {
  const { field, invalid } = useFormControlled();

  return (
    <Textarea
      onChange={field?.onChange}
      onBlur={field?.onBlur}
      value={field?.value}
      name={field?.name}
      id={field?.name}
      ref={mergeRefs([field?.ref, ref])}
      isInvalid={invalid}
      {...props}
    />
  );
});

export const HookFormInputCurrency = React.forwardRef<
  HTMLFormElement,
  NumberFormatProps
>((props, ref) => {
  const { onChange, ...rest } = props;
  const { field, invalid } = useFormControlled();

  const valueHasZeroCents =
    field?.value &&
    field?.value.toString().includes(',') &&
    field?.value.split(',')[1] === '00';

  //aplica regex para nao mostrar centavos se for 0
  const formattedValue = valueHasZeroCents
    ? parseInt(field?.value.split(',')[0].replace(/\D/g, ''))
    : field?.value;

  return (
    <NumberFormat
      prefix="R$ "
      decimalSeparator=","
      thousandSeparator="."
      allowNegative={false}
      allowLeadingZeros={false}
      decimalScale={2}
      customInput={Input}
      onChange={field?.onChange}
      onBlur={field?.onBlur}
      value={valueHasZeroCents ? formattedValue : field?.value}
      name={field?.name}
      id={field?.name}
      getInputRef={mergeRefs([field?.ref, ref])}
      isInvalid={invalid}
      {...rest}
    />
  );
});

export const HookFormInputMask = React.forwardRef<
  HTMLFormElement,
  NumberFormatProps
>((props, ref) => {
  const { field, invalid } = useFormControlled();

  return (
    <NumberFormat
      customInput={Input}
      onChange={field?.onChange}
      onBlur={field?.onBlur}
      value={field?.value}
      name={field?.name}
      id={field?.name}
      getInputRef={mergeRefs([field?.ref, ref])}
      isInvalid={invalid}
      {...props}
    />
  );
});

export const HookFormSwitch = React.forwardRef<HTMLFormElement, SwitchProps>(
  (props, ref) => {
    const { field, invalid } = useFormControlled();
    const { onChange, ...rest } = props;

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      field?.onChange(event.target.checked);
      onChange?.(event);
    };

    return (
      <Switch
        onChange={handleOnChange}
        onBlur={field?.onBlur}
        isChecked={field?.value}
        value={field?.value}
        name={field?.name}
        ref={mergeRefs([field?.ref, ref])}
        isInvalid={invalid}
        {...rest}
      />
    );
  },
);

export const HookFormSelect = React.forwardRef<HTMLFormElement, any>(
  (props, ref) => {
    const { field, invalid } = useFormControlled();
    const { onChange, ...rest } = props;

    const handleOnChange = (value: any) => {
      field?.onChange(value);
      onChange?.(value);
    };

    return (
      <Select
        onChange={handleOnChange}
        onBlur={field?.onBlur}
        value={field?.value}
        name={field?.name}
        inputId={field?.name}
        ref={mergeRefs([field?.ref, ref])}
        isInvalid={invalid}
        {...rest}
      />
    );
  },
);

export const HookFormRadioGroup = React.forwardRef<
  HTMLFormElement,
  HookFormRadioGroupProps
>((props, ref) => {
  const { onChange, options, isDisabled, ...rest } = props;
  const { field, invalid } = useFormControlled();

  const handleOnChange = (value: any) => {
    field?.onChange(value);
    onChange?.(value);
  };

  return (
    <RadioGroup
      onChange={handleOnChange}
      onBlur={field?.onBlur}
      value={field?.value}
      name={field?.name}
      aria-labelledby={field?.name}
      ref={mergeRefs([field?.ref, ref])}
      {...rest}>
      <HStack spacing="24px">
        {options.map(option => (
          <Radio
            _checked={{
              boxShadow: 'none',
              bg: 'black',
              caretColor: 'white',
            }}
            sx={{
              transform: 'scale(1.4)',
              '&[data-checked]::before': {
                content: '""',
                display: 'block',
                width: '50%', // Ajuste o tamanho conforme necessário
                height: '50%', // Ajuste o tamanho conforme necessário
                borderRadius: '50%',
                backgroundColor: 'white', // Cor da bolinha
                position: 'relative',
              },
            }}
            key={option.value}
            value={option.value}
            isInvalid={invalid}
            {...{ isDisabled }}>
            <HStack>
              <span>{option.label}</span>
              {option.tooltipLabel && (
                <TooltipHelperIcon label={option.tooltipLabel} ml="-4px" />
              )}
            </HStack>
          </Radio>
        ))}
      </HStack>
    </RadioGroup>
  );
});

export const HookFormQuillRichEditor = React.forwardRef<HTMLFormElement, any>(
  (props, ref) => {
    const { onChange, ...rest } = props;
    const { field, invalid } = useFormControlled();

    const handleOnChange = (
      content: any,
      delta: any,
      source: any,
      editor: any,
    ) => {
      field?.onChange(editor.getContents());
      onChange?.(content, delta, source, editor);
    };

    return (
      <Box
        sx={{
          '.ql-editor': {
            height: 'auto',
            flex: 1,
          },
          '.ql-toolbar.ql-snow': {
            borderTopStartRadius: 'lg',
            borderTopEndRadius: 'lg',
            borderColor: invalid ? 'red.500' : 'gray.100',
          },
          '.ql-container.ql-snow': {
            display: 'flex',
            borderBottomStartRadius: 'lg',
            borderBottomEndRadius: 'lg',
            borderColor: invalid ? 'red.500' : 'gray.100',
            minH: '200px',
            fontSize: 'md',
            fontFamily: 'Montserrat, sans-serif',
            '&:hover': {
              borderColor: 'gray.50',
            },
          },
        }}
        {...rest}>
        <Suspense
          fallback={
            <Skeleton startColor="gray.50" endColor="gray.100" h="200px" />
          }>
          <ReactQuill
            modules={{
              toolbar: [
                ['bold', 'italic', 'underline'],
                // [{ list: 'bullet' }],
                ['link', 'image', 'video'],
                ['clean'],
              ],
            }}
            formats={[
              'bold',
              'italic',
              'underline',
              // 'bullet',
              'link',
              'image',
              'video',
            ]}
            value={field?.value}
            ref={mergeRefs([field?.ref, ref])}
            onChange={handleOnChange}
          />
        </Suspense>
      </Box>
    );
  },
);

export const HookFormJoditRichEditor = React.forwardRef<HTMLFormElement, any>(
  (props, ref) => {
    const { onChange, ...rest } = props;
    const { field } = useFormControlled();
    const editorRef = useRef<any>(null);
    const { accessToken } = useUserStore();
    var fieldValue: string = field?.value;
    const [hasError, setHasError] = useState(false);
    const [isImageUploading, setIsImageUploading] = useState(false);
    const toast = useToast();

    if (fieldValue.startsWith('<div')) {
      fieldValue = fieldValue
        .replace(
          '<div class="ql-editor" data-gramm="false" contenteditable="true">',
          '<div>',
        )
        .replace(
          '<div class="ql-clipboard" contenteditable="true" tabindex="-1"><br></div>',
          '',
        );
    }

    const handleOnBlur = (value: string) => {
      onChange?.(value);
      field?.onChange(value);
    };

    const handleOnChange = (editorNewValue: string) => {
      fieldValue = editorNewValue;
    };

    const handleShowError = (file: any) => {
      if (file && file.size > 1048576) {
        setHasError(true);
        return true;
      }
      return false; // Impede o upload
    };

    // Configurações do editor
    const editorConfig = useMemo(
      () => ({
        zIndex: 1,
        height: 'auto',
        minHeight: 320,
        maxHeight: 420,
        placeholder:
          'Descreva seu evento com o máximo de detalhes. Quanto mais detalhado mais vendas 🤑',
        allowResizeY: true,
        allowResizeX: false,
        language: 'pt_br',
        addNewLine: false,
        statusbar: false,
        toolbarAdaptive: false,
        askBeforePasteHTML: false,
        defaultActionOnPaste: 'insert_clear_html',
        autofocus: true,
        readonly: false,
        toolbar: true,
        enableDragAndDropFileToEditor: true,
        iframe: true,
        iframeStyle:
          'html{font-family: Montserrat, sans-serif;margin: 0px;}body{overflow:auto !important;height: 100% !important;padding:0px 10px;background:transparent;z-index:2;margin:0px; iframe{width:100%; height: auto; aspect-ratio:16/9 auto}; img{max-width: 100%; user-drag: none; user-select: none; -moz-user-select: none; -webkit-user-drag: none; -webkit-user-select: none; -ms-user-select: none; } };',
        imageDefaultWidth: '100%',
        image: {
          openOnDblClick: false, // Adiciona configuração para ignorar duplo clique na imagem
        },
        buttons: [
          'bold',
          'italic',
          'underline',
          '|',
          'fontsize',
          'brush',
          '|',
          'ul',
          'ol',
          'align',
          '|',
          'image',
          'video',
          'source',
        ],
        colorPickerDefaultTab: 'color',
        popup: {
          img: [
            {
              name: '25%',
              exec: function (editor: Object, current: HTMLImageElement) {
                if (current && current.tagName === 'IMG') {
                  current.style.width = '25%';
                  current.style.height = 'auto';
                }
              },
            },
            {
              name: '50%',
              exec: function (editor: Object, current: HTMLImageElement) {
                if (current && current.tagName === 'IMG') {
                  current.style.width = '50%';
                  current.style.height = 'auto';
                }
              },
            },
            {
              name: '100%',
              exec: function (editor: Object, current: HTMLImageElement) {
                if (current && current.tagName === 'IMG') {
                  current.style.width = '100%';
                  current.style.height = 'auto';
                }
              },
            },
            {
              name: 'Apagar',
              iconURL:
                'https://cdn4.iconfinder.com/data/icons/essentials-71/24/013_-_Trash-512.png',
              label: 'Apagar imagem',
              tooltip: 'Apagar imagem',
              exec: function (editor: Object, current: HTMLImageElement) {
                if (current && current.tagName === 'IMG') {
                  current.remove();
                }
              },
            },
          ],
        },
        uploader: {
          insertImageAsBase64URI: false,
          url: `${process.env.REACT_APP_API_URL}/shared-image`,
          withCredentials: false,
          headers: {
            Authorization: `Basic ${accessToken}`,
          },
          prepareData: function (formData: {
            append: (arg0: string, arg1: string) => void;
            get: (arg0: string) => any;
            delete: (arg0: string) => void;
          }) {
            setIsImageUploading(true);
            var file = formData.get('files[0]');

            if (handleShowError(file)) {
              toast({
                description: 'A imagem deve ter no máximo 1 MB.',
              });
              formData.delete('files[0]');
              formData.delete('path');
              formData.delete('source');
              setIsImageUploading(false);
              return null; // Impede o upload
            } else {
              setHasError(false);
              formData.append('image', file);
              formData.delete('files[0]');
              formData.delete('path');
              formData.delete('source');
              return formData;
            }
          },
          isSuccess: function (resp: any) {
            if (resp.status === 500) {
              setIsImageUploading(false);
              setHasError(true);
              toast({
                description:
                  'Formato de imagem invalido! Use os formatos PGN, JPG, JPEG ou WEBP.',
              });
            }
            console.log(resp);
            if (resp.success === false) {
              setIsImageUploading(false);
              setHasError(true);
              toast({
                description: 'Ocorreu um erro ao enviar a imagem.',
              });
            }
            return !resp.error;
          },
          getMessage: function (resp: { msgs: any[] }) {
            alert(resp.msgs.join('\n'));
          },
          process: function (resp: { url: string }) {
            const imageUrl = resp?.url;
            return imageUrl;
          },
          defaultHandlerSuccess: function (this: any, resp: string) {
            const j = this.jodit ? this.jodit : this;
            // Close the dialog immediately after inserting the image
            if (resp) {
              const tagName = 'img';
              //edetor insertimg function
              const elm = j.createInside?.element(tagName);
              elm.setAttribute('src', resp);
              j.s.insertImage(
                elm as HTMLImageElement,
                null,
                j.o.imageDefaultWidth,
              );

              setIsImageUploading(false);
            }
          },
        },
      }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
    );

    React.useEffect(() => {
      const handleClick = () => {
        const fileBox = document.querySelector(
          '.jodit-drag-and-drop__file-box input[type="file"]',
        ) as HTMLInputElement;
        if (fileBox) {
          fileBox.click();
        }
      };

      const updateFileBoxTexts = () => {
        const fileBox = document.querySelector(
          '.jodit-drag-and-drop__file-box',
        );
        if (fileBox) {
          const strongText = fileBox.querySelector('strong');
          const spanText = fileBox.querySelector('span');

          if (strongText) {
            strongText.textContent = 'Enviar imagem';
          }
          if (spanText) {
            spanText.innerHTML = '<br/>ou arraste';
          }
        }
      };

      const interval = setInterval(() => {
        const uploadButton = document.querySelector('.jodit-ui-button_upload');

        if (uploadButton) {
          uploadButton.removeEventListener('click', handleClick);
          uploadButton.addEventListener('click', handleClick);

          updateFileBoxTexts();
        }
      }, 1);

      return () => clearInterval(interval);
    }, [fieldValue]);

    return (
      <Box position="relative" sx={getEditorStyles(hasError)} {...rest}>
        <Suspense
          fallback={
            <Skeleton startColor="gray.50" endColor="gray.100" h="200px" />
          }>
          <JoditEditor
            ref={ref => (editorRef.current = ref)}
            name="content"
            value={fieldValue}
            //@ts-ignore
            config={editorConfig}
            onBlur={handleOnBlur}
            onChange={handleOnChange}
          />

          <Flex
            display={isImageUploading ? 'flex' : 'none'}
            position="absolute"
            direction="column"
            justifyContent="center"
            alignItems="center"
            borderRadius="5px"
            gap={4}
            top={0}
            opacity={0.98}
            zIndex={99}
            border="2px solid #eee"
            width="100%"
            height="100%"
            bg="white">
            <EllipsisLoading color="black" />
            <Text color="black">Carregando imagem...</Text>
          </Flex>
        </Suspense>
      </Box>
    );
  },
);

// Retorna os estilos do editor
const getEditorStyles = (invalid: boolean) => ({
  '.jodit-container': {
    border: '1,2px',
    borderRadius: '10px',
    borderColor: invalid ? 'red.500' : 'gray.900',
  },

  '.jodit-toolbar__box': {
    borderTopRadius: '10px',
    borderColor: invalid ? 'red.500' : 'gray.900',
  },

  '.jodit-workplace': {
    borderBottomRadius: '10px !important',
  },

  '.jodit-wysiwyg img': {
    userSelect: 'none !important',
    draggable: false,
    pointerEvents: 'none',
    '-moz-user-select': '-moz-none',
    '-khtml-user-select': 'none',
    '-webkit-user-select': 'none',
    '-o-user-select': 'none',
  },

  '.jodit-progress-bar': {
    display: 'none !important',
  },
});

export const HookFormDateTimePicker = React.forwardRef<
  HTMLFormElement,
  Omit<DateTimePickerProps, 'onChange'> & {
    onChange?: DateTimePickerProps['onChange'];
  }
>((props, ref) => {
  const { onChange, ...rest } = props;
  const { field, invalid } = useFormControlled();

  const handleOnChange: DateTimePickerProps['onChange'] = (date, event) => {
    field?.onChange(date);
    onChange?.(date, event);
  };

  return (
    <DateTimePicker
      onChange={handleOnChange}
      onBlur={field?.onBlur}
      value={field?.value}
      name={field?.name}
      ref={mergeRefs([field?.ref, ref])}
      isInvalid={invalid}
      pr="0"
      {...rest}
    />
  );
});

export const HookFormDateTimeInput = React.forwardRef<
  HTMLFormElement,
  Omit<DateTimeInputProps, 'onChange'> & {
    onChange?: DateTimeInputProps['onChange'];
  }
>((props, ref) => {
  const { onChange, ...rest } = props;
  const { field, invalid } = useFormControlled();

  const handleOnChange: DateTimeInputProps['onChange'] = ({ value, event }) => {
    field?.onChange(value);
    onChange?.({ value, event });
  };

  return (
    <DateTimeInput
      onChange={handleOnChange}
      onBlur={field?.onBlur}
      value={field?.value}
      name={field?.name}
      ref={mergeRefs([field?.ref, ref])}
      isInvalid={invalid}
      {...rest}
    />
  );
});
